<template>
<div class="wrap">
  <div class="wrapper">
    <div class="err404">
      404
    </div>
    <div class="def">
      不存在的页面
    </div>
    <div class="tips">
      网页出错或你访问的网页不存在，<a href="https://linwuaiwo.cn">返回首页</a>
    </div>
  </div>
</div>
</template>


<script>
export default {
  name : 'Error404' , 
  mounted() {
    // const oScript = document.createElement('script');
    // oScript.type = 'text/javascript';
    // oScript.src = 'http://qzonestyle.gtimg.cn/qzone/hybrid/app/404/search_children.js';
    // oScript.homePageUrl="/index" ;
    // oScript.homePageName="回到主页" ;
    // document.body.appendChild(oScript);
  }
}
</script>


<script>
export default {
  name : 'Error404' , 
}
</script>


<style scoped>
::selection {
    background:rgb(255, 255, 255);
    color:rgb(82,191,213);
}
.wrap{
  width: 100vw;
  height: 100vh;
  background-color: rgb(114, 205, 223);
  color: #fff;
}
.wrapper{
  /* width: 30vw; */
  /* background-color: pink; */
  text-align: center;
  margin:0 auto;
  padding-top: 20vh;
}
.err404{
  font-size: 12rem;
  font-weight: 600;
  /* background-color: #bfa; */
}
.def{
  font-size: 3rem;
  font-weight: 600;
}
.tips{
  font-size: 1.5rem;
  margin-top: 1rem;
}
</style>